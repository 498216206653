<template>
	<Layout>
		<template>
			<article class="profile__column panel">
				<div class="panel__header">
					<span class="is-flex is-flex-direction-column is-justify-content-center">
						<h3 class="profile__name pt-0 is-semibold is-size-4">{{ $tc('fields.docs', 2) }}</h3>
						<p class="is-size-6">{{ $t('profile.documents') }}</p>
					</span>
				</div>
				<div class="panel__body is-relative" ref="divDocuments">
					<b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
					<ul v-if="documents.length >= 0" class="my-docs is-multiline is-desktop columns">
						<li v-for="d in documents" :key="d.id" class="column">
							<a :href="d.path" target="_blank">
								<b-icon icon="file" size="is-large"></b-icon>
								<span class="mt-3">{{ d.name }}</span>
							</a>
						</li>
						<li class="column">
							<b-upload v-model="uploads" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx" @input="uploadDocs" multiple drag-drop>
								<div class="content has-text-centered">
									<b-icon icon="upload" size="is-large"></b-icon>
								</div>
							</b-upload>
						</li>
					</ul>
					<div class="mt-2 mb-4 tags">
						<span v-for="(file, index) in uploads" :key="index" class="tag is-primary">
							{{ file.name }}
							<button class="delete is-small" type="button" @click="removeDocument(index)"></button>
						</span>
					</div>
				</div>
			</article>
		</template>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Profile'
import Api from '@/services/api'
import { successToast, errorToast } from '@/mixins/toast'
import { responsive } from '@/mixins/responsive'

export default {
	name: 'Password',
	mixins: [responsive],
	components: {
		Layout
	},
	data() {
		return {
			loading: true,
			documents: [],
			uploads: []
		}
	},
	mounted () {
		this.listDocuments()
	},
	methods: {
		async listDocuments () {
			try {
				this.loading = true
				const requestDoc = await Api.get(`user-documents/list-me`)

				if (requestDoc.status == 200) {
					this.documents = requestDoc.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		removeDocument(index) {
			this.uploads.splice(index, 1)
		},
		async updateProfile() {
			try {
				this.loading = true
				const response = await Api.put(`users/password`, this.user)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					successToast(this.$t('alerts.update.success_f', [this.$t('fields.password')]))
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				}
			} finally {
				this.loading = false
			}
		},
		async uploadDocs (docs) {
			try {
				this.loading = true
				let formData = new FormData()
				docs.forEach((d) => {
					formData.append('documents[]', d)
				})

				const response = await Api.post(`user-documents/upload`, formData)
				if (response.status === 200) {
					successToast('Salvo com sucesso')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				}
			} finally {
				this.loading = false
			}

			this.uploads = []
			this.listDocuments()
		}
	}
}
</script>
